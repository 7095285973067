import { ErrorIcon, InfoIcon, WarningIcon } from '@pebl/ui';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  clearNotificationsOfType,
  removeNotification,
  useQngDataStore,
} from '@/stores/qng-data-store';
import { TranslationComponentProps } from '@/types/translation';

import { SiteBannerView } from './site-banner-view';

type SiteBannerDataProps = {} & TranslationComponentProps;

export function SiteBannerData({ t }: SiteBannerDataProps) {
  const location = useLocation();
  const notifications = useQngDataStore((state) => state.notifications);

  /*
   * Listen to location changes to remove error notifications when navigating
   * Note: this may not be the optimal way to do this, so this should be revisited
   */
  useEffect(() => {
    clearNotificationsOfType('error');
  }, [location]);

  const notificationCount = notifications.length;

  const errorNotifications = notifications.filter((n) => n.type === 'error');
  const informationalNotifications = notifications.filter(
    (n) => n.type === 'informational',
  );
  const warningNotifications = notifications.filter(
    (n) => n.type === 'warning',
  );

  const latestNotification =
    errorNotifications.length > 0
      ? errorNotifications[errorNotifications.length - 1]
      : warningNotifications.length > 0
        ? warningNotifications[warningNotifications.length - 1]
        : informationalNotifications.length === 0
          ? undefined
          : informationalNotifications[informationalNotifications.length - 1];

  const handleClose = useCallback(() => {
    if (!latestNotification?.id) {
      return;
    }

    removeNotification(latestNotification.id);
  }, [latestNotification?.id]);

  function getIcon() {
    switch (latestNotification?.type) {
      case 'error':
        return <ErrorIcon outlined />;
      case 'informational':
        return <InfoIcon outlined />;
      case 'warning':
        // Class here is a work around until pebl library is updated
        return <WarningIcon className="size-6" outlined />;
      default:
        return undefined;
    }
  }

  const getVariant = useCallback(() => {
    switch (latestNotification?.type) {
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'informational':
        return 'info';
      default:
        return 'default';
    }
  }, [latestNotification?.type]);

  return (
    <AnimatePresence>
      {notificationCount > 0 && (
        <SiteBannerView
          t={t}
          message={latestNotification?.message}
          closeable={latestNotification?.closeable ?? true}
          icon={getIcon()}
          onClose={handleClose}
          variant={getVariant()}
        />
      )}
    </AnimatePresence>
  );
}
